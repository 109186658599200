import { A, useIsRouting, useNavigate } from "@solidjs/router";
import { createSignal, Match, Show, Switch } from "solid-js";
import { flipIcon } from "~/assets/assets";
import { GiftCardDetails } from "~/server/types/pluto";
import { Spacer } from "~/widgets/spacer";
import { ButtonDark } from "../../verification/components";
import FlipIconDark from "~/assets/svg_icons/flip_icon_dark";
import { createJob } from "~/utils/job";
import { showSnackBar } from "~/shared_states/snackbar";
import { clientRepo } from "~/server/apis/client_repo";
import { generateDeviceVerificationToken } from "~/utils/common";
import { Header } from "~/types";
import {
  GetGiftBoxV3Response,
  GiftBoxSendOtpResponse,
  VerifyOtpResponseV3,
} from "~/server/types/gift";
import { collectOtp } from "../../verification/verification_v3";
import { setCookie } from "~/utils/client_cookie";
import { getPlutoReceiverSessionIdStorageKey } from "./pluto_reveal_route_data";
import { goBack } from "~/shared_states/modal";
import GiftCardWhiteIcon from "~/assets/svg_icons/gift_card_white_icon";
import { GiftCardContent } from "~/components/pluto/gift-card/gift_card";
import { useVerticalDragRevealPlayLottie } from "~/components/vertical_drag_reveal";
import { config } from "~/data/config";

export interface GiftCardProps {
  giftingKey: string;
  giftDetailsAccessor: () => GiftCardDetails;
  giftBoxMetadata: GetGiftBoxV3Response;
  isRtu: boolean;
  sessionId: string | null;
  flipOnCard?: boolean;
  className?: string;
  isPreview: boolean;
  navigateToDetailsRoute: () => void;
}

export function GiftCardComponent(props: GiftCardProps) {
  const navigate = useNavigate();
  const playLottie = useVerticalDragRevealPlayLottie();

  const [isFlipped, setIsFlipped] = createSignal(false);
  const [isLoading, setIsLoading] = createSignal(false);
  const [isFlippedOnce, setIsFlippedOnce] = createSignal(props.isRtu);
  const [sessionId, setSessionId] = createSignal(props.sessionId);
  const isRouting = useIsRouting();

  const sendOtpJob = createJob<GiftBoxSendOtpResponse>({
    initialJob: async () => {
      return await clientRepo.sendGiftOtpV3({
        id: props.giftingKey,
        headers: {
          [Header.FpDeviceToken]: await generateDeviceVerificationToken(),
          [Header.SessionId]: "",
        },
      });
    },
    successCallback: async (sendOtpResponse) => {
      collectOtp<VerifyOtpResponseV3>({
        phoneNumber: props.giftBoxMetadata.customer?.phoneNumber ?? "",
        submitOtp: async (submitOtpProps) => {
          return await clientRepo.verifyGiftOtpV3({
            id: props.giftingKey,
            otp: submitOtpProps.otp,
            otpToken: submitOtpProps.otpToken,
            headers: {
              [Header.FpDeviceToken]: await generateDeviceVerificationToken(),
              [Header.SessionId]: "",
            },
          });
        },
        onVerificationSuccess: (response) => {
          goBack();
          setCookie({
            key: getPlutoReceiverSessionIdStorageKey(props.giftingKey),
            value: response.sessionId,
            expiryInMinutes: config.sesssionDurationInMinutes.gift_receiver,
          });
          setSessionId(response.sessionId);
        },
        otpToken: sendOtpResponse.otpToken,
        resendOtp: async (number) => {
          return await clientRepo.sendGiftOtpV3({
            id: props.giftingKey,
            identifier: number,
            headers: {
              [Header.FpDeviceToken]: await generateDeviceVerificationToken(),
              [Header.SessionId]: "",
            },
          });
        },
      });
    },
    errorCallback: (e: any) => {
      showSnackBar({
        message: e.message,
        level: "error",
      });
    },
  });

  const toggleFlip = () => {
    setIsFlipped(!isFlipped());
    if (!isFlippedOnce()) {
      setIsFlippedOnce(true);
    }
  };

  return (
    <div class="flex w-full max-w-[475px] flex-col items-center justify-start">
      <GiftCardContent
        flipOnCard={isFlippedOnce()}
        giftDetails={props.giftDetailsAccessor}
        isEditable={false}
        isFlipped={isFlipped}
        toggleFlip={toggleFlip}
        class="!h-auto w-full"
      />
      <Spacer height={16} />
      <Switch>
        <Match when={sessionId() || props.isPreview}>
          <Show when={isFlippedOnce()}>
            <div class="flex w-full flex-row items-center justify-center">
              <div class="flex w-full flex-1 items-center justify-center ">
                {flipButton()}
              </div>
              <Show when={props.isRtu}>
                <div class="w-full flex-1 ">{buildMyGiftCardsButton()}</div>
              </Show>
            </div>
            <Spacer height={16} />
          </Show>
          <Switch>
            <Match when={!isFlippedOnce()}>{buildViewGiftCta()}</Match>
            <Match when={isFlippedOnce()}>{buildContinueCta()}</Match>
          </Switch>
        </Match>
        <Match when={!sessionId()}>{buildLoginButton()}</Match>
      </Switch>
      <Spacer height={16} />
    </div>
  );

  function flipButton() {
    return (
      <button
        class="flex items-center justify-center gap-1 py-1.5"
        onClick={toggleFlip}
      >
        <img src={flipIcon} alt="flip icon" />
        <p class="text-mediumBold text-white">
          {isFlipped() ? "View message" : "View my gift"}
        </p>
      </button>
    );
  }

  function buildMyGiftCardsButton() {
    return (
      <A href={`/pluto/reveal/${props.giftingKey}/my-gift-cards`}>
        <div class=" flex flex-row items-center justify-center  text-mediumBold text-white">
          <GiftCardWhiteIcon />
          <Spacer width={4} />
          My gift cards
        </div>
      </A>
    );
  }

  function buildViewGiftCta() {
    return (
      <ButtonDark
        class="w-full !rounded-full"
        isLoading={() => false}
        onClick={async () => {
          toggleFlip();
          await new Promise((resolve) => setTimeout(resolve, 300));
          playLottie();
        }}
      >
        <FlipIconDark />
        <Spacer width={4} />
        View my gift
      </ButtonDark>
    );
  }

  function buildContinueCta() {
    return (
      <ButtonDark
        class="w-full !rounded-full"
        isLoading={isRouting}
        onClick={() => {
          props.navigateToDetailsRoute();
        }}
      >
        Gift details
      </ButtonDark>
    );
  }

  function buildLoginButton() {
    return (
      <ButtonDark
        class="w-full !rounded-full"
        isLoading={() => sendOtpJob.jobState() === "running"}
        onClick={() => {
          sendOtpJob.run();
        }}
      >
        Login to continue
      </ButtonDark>
    );
  }
}
